import tailwindStylesheet from "./tailwind.css";
import type { LinksFunction } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
"@remix-run/react";
import { withSentry } from "@sentry/remix";
import { json } from "@remix-run/node"; // or cloudflare/deno
import { Navigation } from "./components/Navigation";
import { Footer } from "./components/Footer";
import { Analytics } from "@vercel/analytics/react";

export const links: LinksFunction = () => [
{ rel: "stylesheet", href: tailwindStylesheet },
{ rel: "shortcut icon", href: "/icon.png" }];


export const loader = async () => {
  return json({
    SENTRY_DSN: process.env.SENTRY_DSN,
    SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
    GTM_CONTAINER_ID: process.env.GTM_CONTAINER_ID
  });
};

function App() {
  const ENV = useLoaderData<typeof loader>();

  return (
    <html lang="en" className="h-full bg-frost">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <script
          suppressHydrationWarning
          src="https://fast.fonts.net/jsapi/bd454163-7a1d-4e7d-951c-6344391fa45b.js">
        </script>
        <script
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${ENV.GTM_CONTAINER_ID}');
            `
          }}>
        </script>
      </head>
      <body className="h-full">
        <header>
          <Navigation />
        </header>
        <Analytics />
        <Outlet />
        <footer>
          <Footer />
        </footer>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`
          }} />

      </body>
    </html>);

}

export default withSentry(App);